@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-red-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-MainLogo {
  height: 15vmin;
}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-MainLogo {
    animation: zoom-in-zoom-out infinite 3s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoL2R {
    animation: Logo-spinL2R infinite 5s ease-in-out;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoR2L {
    animation: Logo-spinR2L infinite 5s ease-in-out;
  }
} */
.App-header {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logos { 
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}

@keyframes Logo-spinL2R {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Logo-spinR2L {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
  }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #dddddd;
  width: 90vw;
  min-height: 100vh;
}
.tabs_container {  
  height: 100%;
  width: 100%;  
}
.tab_header {
  height: 10vmin;
  width: 100%;  
}
.tab_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vmin;
  width: 95vw;  
}
.dashboardContainer {
  width: 100%;
  height: 82.5vh;
}
.logo_container {  
  height: 15vmin;
  
}
.logo {
  height: 10vmin; 
}
.signout_button {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  margin-bottom: 1vmin;
}